<template>
	<div
		class="services-listing"
		:class="{ container: shouldLimitWidthOnDesktop }"
	>
		<div
			v-for="(service, index) in companyProvidedServices"
			:key="`${service.title}:service-${index}`"
			:class="{ odd: isOddRow(index + 1) }"
			class="service container"
		>
			<NuxtImg
				:alt="service.title"
				:src="service.image"
				class="service-image"
				sizes="400px md:500px lg:600px"
				:placeholder="DEFAULT_NUXT_IMAGE_PLACEHOLDER"
				loading="lazy"
			/>

			<div class="service-text-container">
				<span class="service-title">
					{{ service.title }}
				</span>
				<span v-if="service.subtitle" class="service-subtitle">
					{{ service.subtitle }}
				</span>
				<div class="service-description">
					<ParagraphsWithLineBreak :text="service.description" />
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import type { ServicesListingSection } from '@SHARED/core/entities/sections/ServicesListingSection';
import type { WebsiteStyles } from '@SHARED/core/entities/WebsiteConfig';
import type { CssSize } from '@SHARED/utils/helperTypes';

import { DEFAULT_NUXT_IMAGE_PLACEHOLDER } from '@SHARED/utils/style';
import { getCSSVar } from '@SHARED/utils';

import ParagraphsWithLineBreak from '@SHARED/components/atoms/ParagraphsWithLineBreak.vue';

defineOptions({ name: 'ServicesListing' });

const styles = useState<WebsiteStyles>('styles');

const props = withDefaults(defineProps<ServicesListingSection['config']>(), {
	variant: 'even-odd',
	backgroundColor: null,
	itemGap: '6rem',
	textColor: null,
	fontSizes: null,
	fontWeights: null,
	companyProvidedServices: () => [],
	shouldLimitWidthOnDesktop: false
});

const serviceBackgroundColor = computed<string>(() =>
	getCSSVar(props?.backgroundColor || styles.value.appearance.background)
);

const serviceTextColor = computed<string>(() =>
	getCSSVar(props?.textColor || styles.value.appearance.text)
);

function isOddRow(rowNumber: number): boolean {
	if (props.variant === 'odd') return true;

	if (props.variant !== 'even-odd') return false;

	return rowNumber % 2 === 0;
}

const mobileServicesTitleFontSize = computed<CssSize>(
	() => props.fontSizes?.servicesTitle?.mobile || '2rem'
);
const tabletServicesTitleFontSize = computed<CssSize>(
	() =>
		props.fontSizes?.servicesTitle?.tablet || mobileServicesTitleFontSize.value
);
const desktopServicesTitleFontSize = computed<CssSize>(
	() =>
		props.fontSizes?.servicesTitle?.desktop || tabletServicesTitleFontSize.value
);

const servicesTitleFontWeight = computed<number>(
	() => props.fontWeights?.servicesTitle || 500
);

const mobileServicesSubtitleFontSize = computed<CssSize>(
	() => props.fontSizes?.servicesSubtitle?.mobile || '1.125rem'
);
const tabletServicesSubtitleFontSize = computed<CssSize>(
	() =>
		props.fontSizes?.servicesSubtitle?.tablet ||
		mobileServicesSubtitleFontSize.value
);
const desktopServicesSubtitleFontSize = computed<CssSize>(
	() =>
		props.fontSizes?.servicesSubtitle?.desktop ||
		tabletServicesSubtitleFontSize.value
);

const servicesSubtitleFontWeight = computed<number>(
	() => props.fontWeights?.servicesSubtitle || 500
);

const mobileServicesDescriptionFontSize = computed<CssSize>(
	() => props.fontSizes?.servicesDescription?.mobile || '1.125rem'
);
const tabletServicesDescriptionFontSize = computed<CssSize>(
	() =>
		props.fontSizes?.servicesDescription?.tablet ||
		mobileServicesDescriptionFontSize.value
);
const desktopServicesDescriptionFontSize = computed<CssSize>(
	() =>
		props.fontSizes?.servicesDescription?.desktop ||
		tabletServicesDescriptionFontSize.value
);

const servicesDescriptionFontWeight = computed<number>(
	() => props.fontWeights?.servicesDescription || 400
);
</script>

<style lang="scss" scoped>
.services-listing {
	display: flex;
	flex-direction: column;

	.service {
		display: flex;
		flex-wrap: nowrap;
		gap: 2rem;
		flex: 0 0 auto;
		background-color: v-bind(serviceBackgroundColor);
		padding-top: 2rem;
		padding-bottom: 2rem;
		flex-direction: column;

		@include screen-up(lg) {
			padding-top: calc(v-bind(itemGap) / 2);
			padding-bottom: calc(v-bind(itemGap) / 2);
			flex-direction: row;

			&:first-child {
				padding-top: v-bind(itemGap);
			}

			&:last-child {
				padding-bottom: v-bind(itemGap);
			}
		}

		.service-image {
			width: 100%;
			max-height: 300px;
			object-fit: contain;

			@include screen-up(lg) {
				width: 42%;
				max-height: 400px;
			}
		}

		.service-text-container {
			gap: 1rem;
			display: flex;
			justify-content: center;
			flex-direction: column;
			width: 100%;

			@include screen-up(lg) {
				width: 58%;
				gap: 1.5rem;
			}

			.service-title {
				color: v-bind(serviceTextColor);

				font-size: v-bind(mobileServicesTitleFontSize);
				font-weight: v-bind(servicesTitleFontWeight);

				@include screen-up(md) {
					font-size: v-bind(tabletServicesTitleFontSize);
				}

				@include screen-up(lg) {
					font-size: v-bind(desktopServicesTitleFontSize);
				}
			}

			.service-subtitle {
				color: var(--darkgray);
				font-size: v-bind(mobileServicesSubtitleFontSize);
				font-weight: v-bind(servicesSubtitleFontWeight);

				@include screen-up(md) {
					font-size: v-bind(tabletServicesSubtitleFontSize);
				}

				@include screen-up(lg) {
					font-size: v-bind(desktopServicesSubtitleFontSize);
				}
			}

			.service-description {
				color: v-bind(serviceTextColor);
				font-size: v-bind(mobileServicesDescriptionFontSize);
				font-weight: v-bind(servicesDescriptionFontWeight);

				@include screen-up(md) {
					font-size: v-bind(tabletServicesDescriptionFontSize);
				}

				@include screen-up(lg) {
					font-size: v-bind(desktopServicesDescriptionFontSize);
				}
			}
		}

		&.odd {
			@include screen-up(lg) {
				flex-direction: row-reverse;
			}
		}
	}
}
</style>
